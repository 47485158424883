import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import { Class, OndemandVideo, Visibility, Assessment, FitnessCenter } from "@material-ui/icons";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { bugs, website, server } from "variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { API } from 'aws-amplify';

const useStyles = makeStyles(styles);

function Loading() {
  return <div className="loading-container">Loading...</div>
}

function OverView(props) {
  const classes = useStyles();
  return <GridContainer>
    <GridItem xs={12} sm={6} md={6}>
      <Card>
        <CardHeader color="success" stats icon>
          <CardIcon color="success">
            <Visibility />
          </CardIcon>
          <p className={classes.cardCategory}>Views</p>
          <h3 className={classes.cardTitle}>{props.views}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            Total views of all courses
          </div>
        </CardFooter>
      </Card>
    </GridItem>
    <GridItem xs={12} sm={6} md={6}>
      <Card>
        <CardHeader color="success" stats icon>
          <CardIcon color="success">
            <Class />
          </CardIcon>
          <p className={classes.cardCategory}>Courses</p>
          <h3 className={classes.cardTitle}>{props.courses}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            Number of courses
          </div>
        </CardFooter>
      </Card>
    </GridItem>
    <GridItem xs={12} sm={6} md={6}>
      <Card>
        <CardHeader color="success" stats icon>
          <CardIcon color="success">
            <Assessment />
          </CardIcon>
          <p className={classes.cardCategory}>Lectures</p>
          <h3 className={classes.cardTitle}>{props.lectures}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            Total lectures from all courses
          </div>
        </CardFooter>
      </Card>
    </GridItem>
    <GridItem xs={12} sm={6} md={6}>
      <Card>
        <CardHeader color="success" stats icon>
          <CardIcon color="success">
            <OndemandVideo />
          </CardIcon>
          <p className={classes.cardCategory}>Videos</p>
          <h3 className={classes.cardTitle}>{props.videos}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            Number of video lectures
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  </GridContainer>;
}

function CourseList(props) {
  const classes = useStyles();
  return <Card>
    <CardHeader color="success">
      <h4 className={classes.cardTitleWhite}>Courses</h4>
      <p className={classes.cardCategoryWhite}>
        Published courses
      </p>
    </CardHeader>
    <CardBody>
      <div className="course-list">
        <Table
          rowOpen={props.loadCourse}
          rowDetail={props.openCourse}
          tableHeaderColor="success"
          tableHead={["Name", "Lectures", "Views"]}
          tableData={props.courses.map(course => [course.name, course.lectures.length + "", course.views + ""])}
        />
      </div>
    </CardBody>
  </Card>;
}

function CourseDetail(props) {
  const classes = useStyles();

  let viewChartData = {
    labels: [],
    series: [[]],
  }

  props.course.lectures.forEach((lecture, index) => {
    viewChartData.labels.push("");
    viewChartData.series[0].push(lecture.viewed);
  });

  return <div>
    <GridContainer>
      <GridItem xs={12}>
        Course detail - {props.course.name}
        <hr/>
      </GridItem>
      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="info" stats icon>
            <CardIcon color="info">
              <Visibility />
            </CardIcon>
            <p className={classes.cardCategory}>Views</p>
            <h3 className={classes.cardTitle}>{props.course.views}</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              Course views
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="info" stats icon>
            <CardIcon color="info">
              <Assessment />
            </CardIcon>
            <p className={classes.cardCategory}>Lectures</p>
            <h3 className={classes.cardTitle}>{props.course.lectures.length}</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              Number of lectures
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="info" stats icon>
            <CardIcon color="info">
              <OndemandVideo />
            </CardIcon>
            <p className={classes.cardCategory}>Videos</p>
            <h3 className={classes.cardTitle}>{props.course.videos}</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              Number of video lectures
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="info" stats icon>
            <CardIcon color="info">
              <FitnessCenter />
            </CardIcon>
            <p className={classes.cardCategory}>Labs</p>
            <h3 className={classes.cardTitle}>{props.course.labs}</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              Number of labs
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      
      <GridItem xs={12} sm={12} md={6}>
        <Card chart>
          <CardHeader color="success">
            <ChartistGraph
              className="ct-chart"
              data={viewChartData}
              type="Line"
              options={dailySalesChart.options}
              listener={dailySalesChart.animation}
            />
          </CardHeader>
          <CardBody>
            <h4 className={classes.cardTitle}>Lectures' view</h4>
            <p className={classes.cardCategory}>
              <span className={classes.successText}>
                <Visibility className={classes.upArrowCardCategory} />
              </span>{" "}
              Views by lecture from start to the end of course
            </p>
          </CardBody>
          <CardFooter chart>
            <div className={classes.stats}>
              <Assessment /> Total {props.course.lectures.length} lectures
            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Lectures</h4>
            <p className={classes.cardCategoryWhite}>
              List of lectures in the course
            </p>
          </CardHeader>
          <CardBody>
            <div className="lecture-list">
              <Table 
                tableHeaderColor="info"
                tableHead={["Name", "Type", "Views"]}
                tableData={props.course.lectures.map(lecture => [lecture.name, lecture.type, lecture.viewed + ""])}
              />
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  </div>
}

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCourse: true,
      loadingLecture: true,
      courses: [],
      lectures: [],
      views: 0,
      videos: [],
      currentCourse: {}
    };
  }
  
  loadCourses(lectures) {
    const apiName = 'awslibvnadmin';
    const path = '/course';
    const init = {
      response: true, 
    };
  
    API
      .get(apiName, path, init)
      .then(response => {
        let courses = response.data;
        courses.forEach(course => {
          course.views = 0;
          course.lectures = [];
          course.videos = 0;
          course.labs = 0;

          JSON.parse(course.chapters).forEach(chapter => {
            chapter.lectures.forEach(courseLecture => {
              if (courseLecture.type !== "section") {
                let lecture = lectures.filter(lecture => lecture.id === courseLecture.lectureId)[0];
                course.lectures.push(lecture);
                course.views += lecture.viewed;
                switch(lecture.type) {
                  case "video":
                    course.videos++;
                    break;
                  case "lab":
                    course.labs++;
                    break;
                }
              }
            })
          })
        })

        courses.sort((a, b) => b.views - a.views);

        this.setState({
          loadingCourse: false,
          courses: courses,
          currentCourse: courses[0]
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  
  loadLectures() {
    const apiName = 'awslibvnadmin';
    const path = '/lecture';
    const init = {
      response: true, 
    };
  
    API
      .get(apiName, path, init)
      .then(response => {
        let lectures = response.data, views = 0, videos = 0;

        lectures.forEach(lecture => {
          views += lecture.viewed;
          videos += lecture.type === "video";
        });

        this.setState({
          loadingLecture: false,
          lectures: lectures,
          views: views,
          videos: videos
        });

        this.loadCourses(lectures);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadLectures()
  }

  render() {
    return (this.state.loadingCourse || this.state.loadingLecture ? <Loading /> :
      <div>
        <Card className="main-card">
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <OverView 
                  views={this.state.views}
                  courses={this.state.courses.length} 
                  lectures={this.state.lectures.length}
                  videos={this.state.videos} />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CourseList courses={this.state.courses} 
                  loadCourse={(courseIndex) => {
                    this.setState({
                      currentCourse: this.state.courses[courseIndex]
                    })
                  }}
                  openCourse={(courseIndex) => {
                    window.open(`https://learn.awslibvn.com/#/course/${this.state.courses[courseIndex].id}/`, "_blank").focus();
                  }}/>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <CourseDetail course={this.state.currentCourse} />
      </div>
    );
  }
}
